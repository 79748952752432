import React from 'react';
import DateComponent from './date';
import Author from '../types/author';
import Button from './button';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FluidImage } from '../types/image';

export interface PostCardProps {
  title: string;
  snippet: string;
  category?: string;
  date?: string;
  author: Author;
  photo?: FluidImage;
  slug: string;
}

const PostCard: React.FC<PostCardProps> = ({
  title,
  snippet,
  category,
  date = '',
  author,
  photo,
  slug,
}) => {
  return (
    <div className="max-w-sm w-full md:max-w-full md:flex shadow-lg rounded-lg md:max-h-72 md:h-72 bg-white">
      <div className="h-48 md:h-auto md:w-1/2 md:max-w-1/2 rounded-t-lg md:rounded-t-none md:rounded-l-lg text-center overflow-hidden">
        <Img
          className="object-cover w-full h-full"
          fluid={photo?.asset.fluid}
          alt={photo?.alt}
        />
      </div>
      <div className="rounded-b-lg md:rounded-b-none md:rounded-r-lg p-5 flex flex-col justify-between leading-normal md:max-w-1/2">
        <div className="mb-8">
          <p className="text-sm text-gray-700">{category}</p>
          <div className="text-gray-900 font-bold text-2xl mb-2">{title}</div>
          <p className="text-gray-700 text-lg overflow-hidden md:max-h-2xl">
            {snippet}
          </p>
        </div>
        <div>
          <Link to={slug}>
            <Button>Read More</Button>
          </Link>
        </div>

        <div className="flex items-center mt-4">
          <Img
            className="w-10 h-10 rounded-full mr-4"
            fluid={author.picture?.asset.fluid}
            alt={author.name}
          />
          <div className="text-sm">
            <p className="text-gray-900 leading-none">{author.name}</p>
            <DateComponent className="text-gray-700" dateString={date} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
