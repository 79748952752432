// import { format } from 'date-fns';

export function cn(...args: string[]) {
  return args.filter(Boolean).join(' ');
}

export function mapEdgesToNodes(data: any) {
  if (!data.edges) return [];
  return data.edges.map((edge: any) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }: any) {
  return (slug || {}).current;
}

export function getBlogUrl(slug: any) {
  return `/blog/${slug.current || slug}/`;
}

export function buildImageObj(source: any) {
  return {
    asset: { _ref: source.asset._ref || source.asset._id },
    crop: source.crop && source.crop,
    hotspot: source.hotspot && source.hotspot,
  };
}
