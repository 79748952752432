/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/postcard';
import SEO from '../components/seo';
import PostType from '../types/post';
import GraphQLErrorList from '../components/graphqlerrorlist';
import { mapEdgesToNodes } from '../lib/helpers';

type Props = {
  data: {
    posts: PostType[];
  };
  errors: any[];
};

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(limit: 12, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          coverImage {
            asset {
              _id
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          title
          slug {
            current
          }
          author {
            name
            picture {
              asset {
                fluid(maxWidth: 50) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

const description =
  'Check out our latest blogs on web and mobile development and their impacts on business';
const BlogIndex: React.FC<Props> = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const posts = data && data.posts && mapEdgesToNodes(data.posts);

  return (
    <Layout>
      <SEO title="Our Blog" description={description} />
      <section className="page-bg py-16 h-full w-full flex-1 border-t border-gray-300">
        <div className="max-w-screen-lg mx-auto px-4 md:px-6">
          <div className="mx-auto text-center font-bold text-black mb-8">
            <h1 className="text-4xl mb-3">Our Articles</h1>
            <p>
              Working with web and mobile applications have taught us a few
              things. Here&apos;s where we share our lessons learned and
              experiences while working in the development space.
            </p>
          </div>
          <ul className="flex flex-col py-8 space-y-6">
            {posts.map(
              ({
                slug,
                id,
                title,
                _updatedAt,
                author,
                excerpt,
                coverImage,
                category = { name: 'Category' },
              }: PostType) =>
                slug && (
                  <li key={id}>
                    <Link to={`/blog/${slug.current}`} title={title}>
                      <PostCard
                        title={title}
                        snippet={excerpt}
                        category={category.name}
                        author={author}
                        date={new Date(_updatedAt).toDateString()}
                        slug={`/blog/${slug.current}`}
                        photo={coverImage}
                      />
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex;
